<template>
  <div class="manual">
    <ManualFights :farmers="farmers" :requestFarmers="this.update"></ManualFights>
    <ManualStats>

    </ManualStats>
  </div>
</template>

<script>

import store from "@/store";
import Vue from 'vue';
import ManualFights from "@/components/ManualFights.vue";
import ManualStats from "@/components/ManualStats.vue";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  components: {
    ManualFights,
    ManualStats
  },
  data() {
    return {
      user: store.getters.getUser,
      farmers: [],
      unsubFarmers: null,
    }
  },
  async mounted() {
    this.getFullFarmers({uid: this.user.uid}).then((res) => {
      this.farmers = res.data.farmers;
    })
  },
  methods: {
    update() {
      this.getFullFarmers({uid: this.user.uid}).then((res) => {
        this.farmers = res.data.farmers;
      }).catch(() => {
        console.log("An Error Occurred")
        sleep(1000).then(() => {
          this.update();
        })
      });
    },
    getFullFarmers: Vue.firebase.functions.getFullFarmers
  }
}

</script>

<style>
ul li {
  text-align: left;
}

</style>
