<template>
  <div class="man-stat">
    <div class="grid">
      <div>
        <article>
          <header>
            <div class="grid">
              <div>
                <fieldset class="header-chart">
                  <legend>Period</legend>
                  <label for="daily">
                    <input type="radio" id="daily" name="period" value="daily" v-model="chartPeriod" v-on:change="drawChart" checked>
                    Daily&nbsp;
                  </label>
                  <label for="weekly">
                    <input type="radio" id="weekly" name="period" value="weekly" v-model="chartPeriod" v-on:change="drawChart">
                    Weekly&nbsp;
                  </label>
                  <label for="monthly">
                    <input type="radio" id="monthly" name="period" value="monthly" v-model="chartPeriod" v-on:change="drawChart">
                    Monthly&nbsp;
                  </label>
                  <label for="pmax">
                    <input type="radio" id="pmax" name="period" value="max" v-model="chartPeriod" v-on:change="drawChart">
                    Max
                  </label>
                </fieldset>
              </div>
              <div>
                <fieldset class="header-chart">
                  <legend>Type</legend>
                  <label for="table">
                    <input type="radio" id="table" name="type" value="Table" v-model="chartType" v-on:change="drawChart" checked>
                    Table&nbsp;
                  </label>
                  <label for="linechart">
                    <input type="radio" id="linechart" name="type" value="LineChart" v-model="chartType" v-on:change="drawChart">
                    Line Chart&nbsp;
                  </label>
                  <label for="piechart">
                    <input type="radio" id="piechart" name="type" value="PieChart" v-model="chartType" v-on:change="drawChart">
                    Pie Chart&nbsp;
                  </label>
                  <label for="max">
                    <input type="radio" id="max" name="type" value="max" v-model="chartType" v-on:change="drawChart">
                    Max
                  </label>
                </fieldset>
              </div>
            </div>
          </header>
          <GChart
            :settings="{ packages: ['corechart', 'table'] }"
            :type="chartType"
            :data="chartData"
            :options="chartOptions"
          />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import Vue from "vue";
import store from "@/store";
import { GChart } from 'vue-google-charts'

// Returns the ISO week of the date.
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  var week1 = new Date(date.getFullYear(), 0, 4);
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export default {
  name: 'ManualStats',
  components: {
    GChart
  },
  props: {
    farmers: Array,
    requestFarmers: Function,
  },
  data() {
    return {
      user: store.getters.getUser,
      allUserFights: [],
      dailyStats: [],
      weeklyStats: [],
      monthlyStats: [],
      chartPeriod: "daily",
      chartType: "Table",
      chartData: [],
      chartOptions: {
        chart: {
          title: 'LeekWars Panel Graph'
        },
        chartArea: {
          backgroundColor: {
            fill: '#11191f',
            opacity: 100
          },
        },
      }
    }
  },
  mounted() {
    console.log("Mounted")
//    disableNetwork(Vue.firebase.db).then(() => {
      this.chartPeriod = "daily"
      const q = query(collection(Vue.firebase.db, "LW_FIGHT")
        ,where("uid", "==", this.user.uid)
        ,orderBy("date", "asc")
      );
      console.log("Before")
      getDocs(q).then((snap) => {
        console.log("After")
        snap.forEach((doc) => {
          this.allUserFights.push(doc.data())
        })
        for (var fight of this.allUserFights) {
          var dailyDate = new Date(fight.date.seconds * 1000);
          dailyDate.setHours(0, 0, 0);
          var farmer = fight.fight.farmers1[Object.keys(fight.fight.farmers1)[0]].name;
          if (this.dailyStats[farmer] === undefined) {
            this.dailyStats[farmer] = new Map()
          }
          if (this.dailyStats[farmer][dailyDate] === undefined) {
            this.dailyStats[farmer][dailyDate] = new Object();
            this.dailyStats[farmer][dailyDate].win = 0;
            this.dailyStats[farmer][dailyDate].lose = 0;
            this.dailyStats[farmer][dailyDate].draw = 0;
            this.dailyStats[farmer][dailyDate].total = 0;
            this.dailyStats[farmer][dailyDate].enemiesLevels = []
          }

          var week = dailyDate.getWeek();
          if (this.weeklyStats[farmer] == undefined) {
            this.weeklyStats[farmer] = new Map()
          }
          if (this.weeklyStats[farmer][week] == undefined) {
            this.weeklyStats[farmer][week] = new Object()
            this.weeklyStats[farmer][week].win = 0;
            this.weeklyStats[farmer][week].lose = 0;
            this.weeklyStats[farmer][week].draw = 0;
            this.weeklyStats[farmer][week].total = 0;
            this.weeklyStats[farmer][week].enemiesLevels = []
          }

          var month = dailyDate.getMonth();
          if (this.monthlyStats[farmer] == undefined) {
            this.monthlyStats[farmer] = new Map()
          }
          if (this.weeklyStats[farmer][month] == undefined) {
            this.monthlyStats[farmer][month] = new Object()
            this.monthlyStats[farmer][month].win = 0;
            this.monthlyStats[farmer][month].lose = 0;
            this.monthlyStats[farmer][month].draw = 0;
            this.monthlyStats[farmer][month].total = 0;
            this.monthlyStats[farmer][month].enemiesLevels = []
          }

          this.dailyStats[farmer][dailyDate].total += 1;
          this.weeklyStats[farmer][week].total += 1;
          this.monthlyStats[farmer][month].total += 1;
          if (fight.fight.winner == 1) {
            this.dailyStats[farmer][dailyDate].win += 1;
            this.weeklyStats[farmer][week].win += 1;
            this.monthlyStats[farmer][month].win += 1;
          } else if (fight.fight.winner == 2) {
            this.dailyStats[farmer][dailyDate].lose += 1;          
            this.weeklyStats[farmer][week].lose += 1;
            this.monthlyStats[farmer][month].lose += 1;
          } else {
            this.dailyStats[farmer][dailyDate].draw += 1;
            this.weeklyStats[farmer][week].draw += 1;
            this.monthlyStats[farmer][month].draw += 1;
          }

        }
        console.log(this.dailyStats);
        console.log(this.weeklyStats);
        console.log(this.monthlyStats);
        this.drawChart();
//      });
    })    
  },
  methods: {
    drawChart() {
      var stats = undefined;
      if (this.chartPeriod == 'daily') {
        stats = this.dailyStats;
      } else if (this.chartPeriod == 'weekly') {
        stats = this.weeklyStats;
      } else if (this.chartPeriod == 'monthly') {
        stats = this.monthlyStats;
      }

      if (this.chartType == "Table") {
        var data = [['Date', 'Farmer', 'FightCount', 'Win', 'Lose', 'Ratio']];
        for (const [farmer, dateStats] of Object.entries(stats)) {
          for (const [date, fightsStats] of Object.entries(dateStats)) {
            data.push([date, farmer, fightsStats.total, fightsStats.win, fightsStats.lose, fightsStats.win / fightsStats.lose])
          }
        }
        this.chartData = data;
      } else if (this.chartType == "LineChart") {
        console.log("CHART LINE")
        var cols = ["Date"];

        var farmers = [];
        for (const [farmer, dateStats] of Object.entries(stats)) {
          console.debug(dateStats)
          if (!cols.includes(farmer)) {
            cols.push(farmer);
            farmers.push(farmer);
          }
        }
        console.log(cols)
        console.log(farmers)
        
        var dates = [];
        for (const [farmer, dateStats] of Object.entries(stats)) {
          console.debug(farmer)
          for (const [date, fightsStats] of Object.entries(dateStats)) {
            console.debug(fightsStats)
            if (!dates.includes(date)) {
              dates.push(date);
            }
          }
        }
        console.log(dates)

        var lines = [];
        for (var date of dates) {
          var line = [date]
          for (var farmer of farmers) {
            var ratio = 0.0;
            if (stats[farmer][date] != undefined) {
              ratio = stats[farmer][date].win / stats[farmer][date].lose;
            }
            line.push(ratio);
          }
          lines.push(line);
        }
        console.log(lines);

        data = [cols, ...lines]
        console.log("HELLO")
        console.log(data);
        this.chartData = data;
      }
      console.log(this.chartPeriod)
      console.log(this.chartType)
    }
  }
}

</script>

<style>
.header-chart {
  display: inline-flex;
}

.google-visualization-table-tr-head {
  background-color: #1f2d38 !important;
  background-image: unset !important;
}
.google-visualization-table .gradient {
  background-color: #1f2d38 !important;
  background-image: unset !important;
}

.google-visualization-table-table th {
  border: unset !important;
}

.google-visualization-table-tr-sel {
  background-color: #2c3e5 !important;
}
.google-visualization-table-tr-even {
  background-color: var(--background-color) !important;
}
.google-visualization-table-tr-odd {
  background-color: #1f2d38 !important;
}
.google-visualization-table-table td {
  border: unset !important;
}
:not(thead)>*>td {
    --font-size: 1.2em;
}
</style>