<template>
  <div class="man">
    <div class="grid">
      <div>
        <article>
          <header>Available Farmers</header>
          <ul>
            <li v-for="farmer in farmers" v-bind:key="farmer.name">
              {{ farmer.name }} ({{ farmer.talent }}) ({{ farmer.fights }} Fights) <a role="button" class="outline" v-on:click="buyFights(farmer)">Buy Fights</a>
              <ul>
                <li v-for="leek in farmer.leeks" v-bind:key="leek.id">
                  {{ leek.name }} LVL{{ leek.level }} ({{ leek.talent }})
                </li>
              </ul>
            </li>
          </ul>
          <footer v-if="currentManuals.length > 0">
            <div class="prog" v-for="currentManual in currentManuals" v-bind:key="currentManual.did">
              {{ currentManual.login }} : {{ currentManual.type }} : {{ currentManual.fightDone.length }}/{{ currentManual.fightCount }}
              <progress :value="currentManual.fightDone.length" :max="currentManual.fightCount"></progress>
            </div>
          </footer>
        </article>
      </div>
      <div>
        <article>
          <header>Manual Fights</header>
          <form v-on:submit="startFights">
            <fieldset>
              <label for="farmer">Farmer</label>
              <select id="farmer" v-model="selectedFarmer">
                <option v-for="farmer in farmers" v-bind:key="farmer.id" :value="farmer">{{ farmer.name }}</option>
              </select>
            </fieldset>
            <fieldset v-if="selectedFarmer != null">
              <label for="leek">Leek</label>
              <select id="leek" v-model="selectedLeek">
                <option value="None">Farmer Fight</option>
                <option v-for="leek in selectedFarmer.leeks" v-bind:key="leek.id" :value="leek.id">{{ leek.name }}</option>
              </select>
            </fieldset>
            <fieldset v-if="selectedFarmer != null">
              <label for="fights">Fight Count
                <input type="range" min="0" :max="selectedFarmer.fights" value="50" id="fights" name="fights" v-model="fightCount">
              </label>
              {{ fightCount }} Fights
            </fieldset>
            <button v-if="selectedFarmer != null && selectedLeek != null && fightCount > 0" type="submit">Start Fights</button>
          </form>
        </article>
      </div>
    </div>
    <div class="grid">
      <div>
        <article>
          <header>Today's fights</header>
          <table>
            <thead>
              <tr>
                <th>Hour</th>
                <th>Count</th>
                <th>Win</th>
                <th>Lose</th>
                <th>Ratio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fight in todayManual" v-bind:key="fight.fightDone.total * fight.fightDone.win">
                <td>{{ new Date(fight.date.seconds * 1000).toLocaleTimeString('fr-FR', {hour12: false}) }}</td>
                <td>{{ fight.fightDone.total }}</td>
                <td>{{ fight.fightDone.win }}</td>
                <td>{{ fight.fightDone.lose }}</td>
                <td>{{ fight.fightDone.ratio }}</td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, addDoc, query, where, onSnapshot } from "firebase/firestore";
import Vue from "vue";
import store from "@/store";

export default {
  name: 'ManualFights',
  props: {
    farmers: Array,
    requestFarmers: Function,
  },
  data() {
    return {
      user: store.getters.getUser,
      selectedFarmer: null,
      selectedLeek: null,
      fightCount: 0,
      currentManuals: [],
      todayManual: [],
      oldReport: [],
    }
  },
  methods: {
    buyInMarket: Vue.firebase.functions.buyInMarket,
    startFights(e) {
      e.preventDefault();

      console.log("SELECTED LEEK (TYPE) : ")
      console.log(this.selectedLeek)

      let uid = this.user.uid;
      let login = this.selectedFarmer.login;
      let type = this.selectedLeek;
      let fightCount = this.fightCount;

      addDoc(collection(Vue.firebase.db, "LW_MANUAL"), {
        uid: uid,
        login: login,
        type: type,
        date: new Date(),
        fightCount: fightCount,
        status: 0,
        fightDone: []
      });
      this.requestFarmers();
    },
    async buyFights(farmer) {
      await this.buyInMarket({
        uid: this.user.uid,
        login: farmer.login,
        object: "100fights"
      });
      this.requestFarmers();
    },
    getStatFromDone(list) {
      var total = list.length;
      var win = 0;
      var lose = 0;
      for (var fight of list) {
        if (fight["winner"] == 2) {
          lose++;
        } else if (fight["winner"] == 1) {
          win++;
        }
      }
      var ratio = win / lose;

      return {
        total: total,
        win: win,
        lose: lose,
        ratio: ratio
      };
    },
  },
  mounted() {
    this.$nextTick(function () {
      const qC = query(collection(Vue.firebase.db, "LW_MANUAL"),
        where("uid", "==", this.user.uid),
        where("status", "==", 0)
      );
      onSnapshot(qC, (snap) => {
        const cm = [];
        snap.forEach((doc) => {
          var data = doc.data()
          data.did = doc.id;
          cm.push(data);
        });
        this.currentManuals = cm;
      });

      const qO = query(collection(Vue.firebase.db, "LW_MANUAL"),
        where("uid", "==", this.user.uid),
        where("status", "==", 1)
      );
      onSnapshot(qO, (snap) => {
        const cm = [];
        snap.forEach((doc) => {
          var values = doc.data();
          var fd = [];
          for (var f of values.fightDone) {
            fd.push(JSON.parse(f));
          }
          values.fightDone = this.getStatFromDone(fd);
          cm.push(values);
        });
        this.todayManual = cm;
      });
      this.requestFarmers();
    });
  }
}

/**
 * 
 * LW_MANUAL
 * login: accountLogin
 * type: LeekId (INT) / None
 * ____________________ COMMON WITH AUTO FIGHT _________________
 * status: 0 (Waiting), (1 Done), (2 Error)
 * fightCount: INT
 * fightDone: [] array of Fight
 *
**/

</script>